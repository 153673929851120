import { ProfileFieldsSelfServiceContainer, ProfileHistorySelfServiceMap, ProfileHistorySelfServiceMapContainer } from './cartFieldsSelfService.module.css';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  notShowOnMobile: {
    marginRight: (matches) => (matches ? 0 : '20px'),
  },
}));

const CartFieldsSelfService = ({ formik, disableControlls }) => {
  const matches = useMediaQuery('(max-width:600px)');

  const classes = useStyles(matches);

  const mapUrl = "https://yandex.ru/map-widget/v1/?ll=33.518753%2C44.615367&mode=whatshere&whatshere%5Bpoint%5D=33.518492%2C44.615223&whatshere%5Bzoom%5D=17&z=20.65";

  return (
    <div className={ProfileFieldsSelfServiceContainer}>
      <div className={ProfileHistorySelfServiceMapContainer}><iframe title="Карта самовывоза" src={mapUrl} width="100%" height="400" frameBorder="1" allowFullScreen className={ProfileHistorySelfServiceMap} /></div>
      <TextField
        id="name-deliveryForm"
        label="Ваше имя:"
        type="text"
        required
        fullWidth
        autoComplete="name"
        disabled={disableControlls}
        classes={{ root: classes.notShowOnMobile }}
        name="name"
        margin={matches ? 'normal' : 'none'}
        placeholder="Иван Иванов"
        variant="outlined"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.name)}
        helperText={formik.errors.name}
      />
    </div>
  );
};

export default CartFieldsSelfService;
